import React from "react"
import { LazyLoadImage } from "react-lazy-load-image-component"
import email from "../images/email.png"
import phonecall from "../images/phone-call.png"

class ContactInformation extends React.Component {
  render() {
    return (
      <div className="mt-4 text-center">
        <ul className="checks-ul text-left">
          <p>
            We're really glad you found us. Giving us your information should be
            about your needs as a leader and answering the questions you have.
            We aren't here to sell you something. Instead, we want to support
            you through what we know best - coaching!
          </p>
          <p>When you contact us, you can expect to receive:</p>
          <li className="list-checks my-3">
            Additional information about our coaching programs.
          </li>
          <li className="list-checks my-3">
            Answers to all your questions about coaching and leadership
            development.
          </li>
          <li className="list-checks my-3">
            A quick, power packed coaching session to explore your leadership
            potential.
          </li>
          <li className="list-checks my-3">
            Custom solutions designed to meet your unique leadership development
            needs.
          </li>
        </ul>
        <h5>CONTACT INFORMATION</h5>
        <p>
          <LazyLoadImage src={email} width="100px" alt="EMAIL" />
          <br />
          <a href="mailto:impact@coachingforgood.org" className="text-dark">
            impact@coachingforgood.org
          </a>
        </p>
        <p className="mt-5">
          <LazyLoadImage src={phonecall} width="100px" alt="PHONE" />
          <br />

          <a href="tel:719-800-2149" className="text-dark">
            719-800-2149
          </a>
        </p>
        <br />
      </div>
    )
  }
}
export default ContactInformation
