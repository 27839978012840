import React from "react"
import Layout from "../components/layout"
import "../components/layout.css"
import SEO from "../components/seo"
import ContactInfo from "../components/contact-information"

const Testimonials = () => (
	<Layout>
		<SEO
			title="Client Testimonials"
			description="Here how coaching has impacted our clients."
		/>
		<div className="page-headline">
			<div className="container">
				<div className="section-heading text-center">
					<h6 className="font-weight-bold text-uppercase flair">
						Testimonials
					</h6>
					<h1>
						<strong>COACHING CHANGES LIVES…</strong>
					</h1>
				</div>
			</div>
			<div className="headline-bg" />
		</div>
		<div className="page-content testimonials">
			<div class="container">
				<div className="row justify-content-between align-items-center pt-5">
					<div className="col-md-6 text-center pb-5 pb-md-0">
						<strong>BUT DON’T TAKE OUR WORD FOR IT…</strong>
						<div className="testimonial-video mt-3">
							<iframe
								width="100%"
								height="450"
								src="https://www.youtube.com/embed/Mbc9ynVPqY4?rel=0"
								frameBorder="0"
								allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
								allowFullScreen
								title="Testimonial Video"
							/>
						</div>
					</div>
					<div className="col-md-6 text-center pb-5 pb-md-0">
						<ContactInfo />
					</div>
				</div>
			</div>
		</div>
	</Layout>
)

export default Testimonials
